<template>
  <div>
    <a-form-model layout="inline">
      <a-form-model-item label="二维码名称">
        <a-input v-model="form.name" :allow-clear="true" placeholder="请输入二维码名称"/>
      </a-form-model-item>
      <a-form-model-item label="时间筛选">
        <a-select v-model="form.time_type"
                  style="width: 120px; margin-right: 10px;">
          <a-select-option value="1">创建时间</a-select-option>
          <a-select-option value="2">更新时间</a-select-option>
        </a-select>
        <a-range-picker v-model="form.date"/>
      </a-form-model-item>
      <a-form-model-item class="full-width flex flex-end" style="text-align: right;">
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button class="mr-30" @click="onReset">重置</a-button>
        <a-button type="primary" @click="onCreate">新增二维码</a-button>
      </a-form-model-item>
    </a-form-model>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="mt-20 table small-cell-table">

      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
        <a-button type="link" @click="toDetail(record)">管理视频</a-button>
        <a-button type="link" @click="onDownload(record)">下载二维码</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>

    <CreateCodeModal ref="createCodeModalRef" @closed="onClosed"/>
  </div>
</template>

<script>
import {
  getCodeList,
} from './api';

import Pagination, { getPagination, updatePagination } from '@components/Pagination.vue';
import CreateCodeModal from './components/CreateCodeModal.vue';
import downloadFile from '@/utils/download';

const form = {
  name: '',
  time_type: '1',
  date: [undefined, undefined],
  start_day: '',
  end_day: '',
};

const columns = [
  {
    width: '60px',
    align: 'center',
    title: '二维码名称',
    key: 'title',
    dataIndex: 'title',
  },
  {
    width: '180px',
    align: 'center',
    title: '二维码地址',
    key: 'code_url',
    dataIndex: 'code_url',
  },
  {
    width: '100px',
    align: 'center',
    title: '创建时间',
    key: 'created_at',
    dataIndex: 'created_at',
  },
  {
    width: '50px',
    align: 'center',
    title: '课程数量',
    key: 'course_num',
    dataIndex: 'course_num',
  },
  {
    width: '60px',
    align: 'center',
    title: '创建账号',
    key: 'created_user_name',
    dataIndex: 'created_user_name',
  },
  {
    width: '100px',
    align: 'center',
    title: '更新时间',
    key: 'updated_at',
    dataIndex: 'updated_at',
  },
  {
    width: '180px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
    CreateCodeModal,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      form: { ...form },

      columns,
      data: [],
      pagination: { ...getPagination() },
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onReset() {
      this.form = { ...form };
      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
        start_day: this.form.date[0]?.format('YYYY-MM-DD') || '',
        end_day: this.form.date[1]?.format('YYYY-MM-DD') || '',
        page,
        per_page: pageSize,
      };
      delete params.date;

      const data = await getCodeList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },

    onDownload(record) {
      if (!record?.code_url) {
        this.$message.error('未找到该二维码');
        return;
      }
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      downloadFile(record.code_url, `${record.title}.png`).finally(() => {
        this.$message.success('下载成功');
        setTimeout(() => {
          this.downloading = false;
        }, 1000);
      });
    },

    onCreate() {
      this.showCreateCodeModal({});
    },
    onEdit(record) {
      this.showCreateCodeModal(record);
    },
    showCreateCodeModal(record) {
      if (this.$refs.createCodeModalRef?.show) {
        this.$refs.createCodeModalRef.show({ ...(record || {}) });
      }
    },
    onClosed(evt) {
      if (evt?.all) {
        this.onSearch();
      } else if (evt?.item) {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },

    toDetail(record) {
      switch (record?.type) {
        case  1:
          this.toCourse(record);
          break;
        case  2:
          this.toChapter(record);
          break;
        default :
          this.toChapter(record);
      }
    },
    toCourse(record) {
      this.$router.push({
        path: '/index/dos/list/course',
        query: {
          dosId: record.id,
        },
      });
    },
    toChapter(record) {
      this.$router.push({
        path: '/index/dos/list/chapter',
        query: {
          dosId: record.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>

<template>
  <a-modal :visible="shown"
           :title="title"
           :width="900"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           :footer="null"
           :after-close="afterClosed"
           @cancel="close()">
    <div class="wrapper">
      <a-form-model ref="formRef"
                    :model="form"
                    label-align="right"
                    layout="inline"
                    @submit="onSearch"
                    class="form-model flex-grow">
        <a-form-model-item label="所属组织机构" prop="org_ids" class="form-item">
          <app-select-org v-model="form.org_ids"/>
        </a-form-model-item>
        <a-form-model-item label="所属岗位" prop="post_ids" class="form-item">
          <app-select-job v-model="form.post_ids"/>
        </a-form-model-item>
        <a-form-model-item label="课程状态" prop="status" class="form-item">
          <a-select v-model="form.status"
                    :allow-clear="true"
                    placeholder="课程状态"
                    class="selection-status">
            <a-select-option value="1">已发布</a-select-option>
            <a-select-option value="2">未发布</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="课程标题" prop="title" class="form-item">
          <a-input v-model="form.title"
                   :allow-clear="true"
                   placeholder="请输入课程标题"/>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" :loading="loading" class="mr-30" @click="onSearch">搜索</a-button>
          <a-button :loading="loading" @click="onReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>

      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               class="mt-20 table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="onAddCourse(record)">添加课程</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <AddCourseTitleModal ref="addCourseTitleModalRef"
                         @closed="onAddCourseClosed"/>
  </a-modal>
</template>

<script>
import {
  getFreeCourseList,
} from '../api';

import AddCourseTitleModal from './AddCourseTitleModal.vue';
import Pagination, { getPagination, updatePagination } from '@components/Pagination.vue';

const columns = [
  {
    width: '50px',
    align: 'center',
    title: '类型',
    key: 'type_name',
    dataIndex: 'type_name',
  },
  {
    width: '140px',
    align: 'center',
    title: '标题',
    key: 'title',
    dataIndex: 'title',
  },
  {
    width: '50px',
    align: 'center',
    title: '状态',
    key: 'status_name',
    dataIndex: 'status_name',
  },
  {
    width: '80px',
    align: 'center',
    title: '创建者',
    key: 'creator_name',
    dataIndex: 'creator_name',
  },
  {
    width: '120px',
    align: 'center',
    title: '发布时间',
    key: 'shutup_time',
    dataIndex: 'shutup_time',
  },
  {
    width: '60px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'SelectCourseModal',
  components: {
    AddCourseTitleModal,
    Pagination,
  },
  data() {
    return {
      shown: false,
      loading: false,

      title: '添加课程',
      modified: false,

      form: {
        code_id: '',
        chapter_id: '',
        title: '',
        org_ids: '',
        post_ids: '',
        status: undefined,
      },

      columns,
      data: [],
      pagination: { ...getPagination() },
    };
  },
  methods: {
    show({ code_id, chapter_id } = {}) {
      this.form.code_id = code_id || '';
      this.form.chapter_id = chapter_id || '';

      this.shown = true;
      this.onSearch();
    },
    close() {
      this.$emit('closed', { modified: this.modified });
      this.shown = false;
    },
    afterClosed() {
      this.form = {
        code_id: '',
        chapter_id: '',
        title: '',
        org_ids: '',
        post_ids: '',
        status: undefined,
      };
    },

    onReset() {
      this.form = {
        ...this.form,
        title: '',
        org_ids: '',
        post_ids: '',
        status: undefined,
      };
      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
        page,
        per_page: pageSize,
      };
      delete params.chapter_id;

      const data = await getFreeCourseList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取课程列表失败');
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },

    onAddCourse(record) {
      if (this.$refs.addCourseTitleModalRef?.show) {
        this.$refs.addCourseTitleModalRef.show({
          code_id: this.form.code_id,
          chapter_id: this.form.chapter_id,
          course_id: record.id,
          code_course_title: record.title || '',
        });
      }
    },
    onAddCourseClosed(evt) {
      if (evt) {
        this.onSizeChange(this.pagination.current, this.pagination.pageSize);
        this.modified = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  max-height: 80vh;
  overflow-y: auto;
}

.form-item {
  margin-bottom: 5px;

  ::v-deep .input {
    min-width: 240px !important;
  }
}

.selection-status {
  min-width: 120px;
}
</style>

<template>
  <a-select ref="selectRef"
            v-model="selectionValue"
            placeholder="请选择课程"
            :mode="selectionMode"
            :show-search="true"
            :filter-option="false"
            :get-popup-container="getContainer"
            :allow-clear="true"
            :disabled="disabled"
            class="select"
            @focus="resetSearch"
            @blur="resetSearch"
            @popupScroll="onScroll"
            @search="onSearch"
            @change="onChange">
    <a-select-option v-for="(item) in list"
                     :key="item.id"
                     :value="item.id">
      <a-tooltip v-if="showTooltip" :title="item.name" placement="left">{{ item.title }}</a-tooltip>
      <span v-else>{{ item.title }}</span>
    </a-select-option>
  </a-select>
</template>

<script>
import { getCourseListForSelection } from '../api';

export default {
  name: 'SelectCourse',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [String, Number], default: '' },
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) => ['single', 'multiple'].includes(val),
    },
    showTooltip: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fixedTags: { type: Array, default: () => ['a-form-model-item', 'AFormModelItem'] },
  },
  computed: {
    selectionMode() {
      switch (this.mode) {
        case 'single':
          return 'default';
        case 'multiple':
          return 'multiple';
        default:
          return 'default';
      }
    },
    getContainer() {
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag
            || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.setPost(val);
      },
    },
  },
  data() {
    return {
      loading: false,
      list: [],
      selectionValue: undefined,

      searchTxt: '',
      page: 0,
      pageSize: 80,
      total: 0,
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    resetSearch() {
      this.onSearch('');
    },
    onSearch(value) {
      this.searchTxt = value;
      this.page = 0;
      this.total = 0;
      this.getData();
    },
    async getData() {
      if (this.page && this.total <= this.page * this.pageSize) {
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getCourseListForSelection({
        title: this.searchTxt,
        page: this.page + 1,
        per_page: this.pageSize,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      if (this.page + 1 === 1) {
        this.list = data?.data || [];
      } else {
        this.list = this.list.concat(...(data?.data || []));
      }

      const pager = data?.meta?.pagination || {};
      this.page = +pager.current_page;
      this.pageSize = +pager.per_page;
      this.total = +pager.total || 0;
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (scrollTop + 1.2 * height > scrollHeight && this.page * this.pageSize < this.total) {
        this.getData();
      }
    },

    setPost(value) {
      value = value || this.value;
      const posts = `${value || ''}`.split(',').map((i) => +i).filter((i) => !!i);

      if (this.mode === 'single') {
        this.selectionValue = posts[0] || undefined;
      }
      if (this.mode === 'multiple') {
        this.selectionValue = [...posts];
      }
    },

    onChange() {
      let evt = '';

      if (this.mode === 'single') {
        evt = this.selectionValue ? `${this.selectionValue}` : undefined;
      }
      if (this.mode === 'multiple') {
        evt = this.selectionValue?.length ? this.selectionValue.join(',') : undefined;
      }

      this.$emit('change', evt);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 350px;
}
</style>

<template>
  <Draggable
    :list="reorderWrapper[source]"
    :disabled="disabled && reorderWrapper[disabled]"
    ghostClass="active-placeholder"
    tag="tbody"
    animation="300"
    @change="onChange">
    <slot/>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'DraggableTableBody',
  components: { Draggable },
  inject: {
    source: {
      from: 'reorderSource',
      default: '',
    },
    disabled: {
      from: 'reorderDisabled',
      default: '',
    },
    reorderWrapper: {
      from: 'reorderWrapper',
      default: {},
    },
  },
  methods: {
    onChange(evt) {
      if (this.reorderWrapper?.handleTableRowOrderUpdate) {
        this.reorderWrapper?.handleTableRowOrderUpdate(evt);
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>


<!--<template>-->
<!--  <a-table :components="components"/>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  provide() {-->
<!--    return {-->
<!--      reorderSource: this.data,-->
<!--      reorderDisabled: this.disabled,-->
<!--    };-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      components: {-->
<!--        body: {-->
<!--          wrapper: DraggableTableBody,-->
<!--        },-->
<!--      },-->
<!--    };-->
<!--  },-->
<!--  methods: {-->
<!--    handleTableRowOrderUpdate(evt) {-->
<!--      console.log(evt);-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->

import { render, staticRenderFns } from "./EditCourseTitleModal.vue?vue&type=template&id=4afa4740&scoped=true"
import script from "./EditCourseTitleModal.vue?vue&type=script&lang=js"
export * from "./EditCourseTitleModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4afa4740",
  null
  
)

export default component.exports
<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           :footer="null"
           :after-close="afterClosed"
           @cancel="close()">
    <a-form-model ref="formRef"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 6}"
                  :wrapper-col="{span: 18}"
                  label-align="right"
                  class="form-model flex-grow mr-60"
                  @submit="onSubmit">
      <a-form-model-item label="目录名称" prop="title" class="form-item">
        <a-input ref="titleRef"
                 v-model="form.title"
                 placeholder="请填写目录名称"/>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{span: 24, offset: 0}" style="text-align: center;">
        <a-button type="primary" :loading="loading" @click="onSubmit">
          <span v-if="!id">创建</span>
          <span v-else>保存</span>
        </a-button>
      </a-form-model-item>
    </a-form-model>

  </a-modal>
</template>

<script>
import { saveChapter } from '../api';

export default {
  name: 'CreateChapterModal',
  data() {
    return {
      shown: false,
      loading: false,

      id: '',
      title: '',

      form: {
        title: '',
        code_id: '',
      },

      rules: {
        title: [{ required: true, trigger: 'blur', message: '请填写目录名称' }],
      },
    };
  },
  methods: {
    show({ id, code_id, title } = {}) {
      this.id = id;
      this.title = id ? `修改目录名称` : `新建目录`;
      this.form.code_id = code_id || '';
      this.form.title = title || '';

      this.shown = true;
      this.$nextTick(() => {
        this.$refs.titleRef.focus();
      });
    },
    close(evt) {
      this.$emit('closed', evt);
      this.shown = false;
    },
    afterClosed() {
      this.id = '';
      this.title = '';
      this.form = {
        title: '',
        code_id: '',
      };
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((validated) => {
          if (validated) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
    onSubmit() {
      this.validate().then(() => {
        this.onSave();
      });
    },
    async onSave() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
      };
      if (this.id) {
        params.chapter_id = this.id;
      }

      const data = await saveChapter(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.close({ all: !this.id, item: params });
    },
  },
};
</script>

<style scoped lang="scss">

</style>

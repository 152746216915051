<template>
  <div>
    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading || fetching"
             :components="components"
             bordered
             row-key="id"
             class="mt-20 table small-cell-table">

      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
        <a-button type="link" @click="onDelete(record)">删除</a-button>
        <a-button type="link" @click="viewLog(record)">查看日志</a-button>
      </template>
    </a-table>

    <EditCourseTitleModal ref="editCourseTitleModalRef"
                          @closed="editModalClosed"/>
  </div>
</template>

<script>
import {
  deleteCourse,
  reorderCourse,
} from '../api';

import DraggableTableBody from '@/components/table/DraggableTableBody.vue';
import EditCourseTitleModal from './EditCourseTitleModal.vue';

const columns = [
  {
    width: '50px',
    align: 'center',
    title: '类型',
    key: 'mold_name',
    dataIndex: 'mold_name',
  },
  {
    width: '120px',
    align: 'center',
    title: '课程标题',
    key: 'course_title',
    dataIndex: 'course_title',
  },
  {
    width: '120px',
    align: 'center',
    title: 'DOS课程名',
    key: 'code_course_title',
    dataIndex: 'code_course_title',
  },
  {
    width: '50px',
    align: 'center',
    title: '学习方式',
    key: 'course_learnMode_name',
    dataIndex: 'course_learnMode_name',
  },
  {
    width: '50px',
    align: 'center',
    title: '创建者',
    key: 'course_creator_name',
    dataIndex: 'course_creator_name',
  },
  {
    width: '50px',
    align: 'center',
    title: '状态',
    key: 'course_status_name',
    dataIndex: 'course_status_name',
  },
  {
    width: '120px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'CourseList',
  components: {
    EditCourseTitleModal,
  },
  props: {
    data: { type: Array, default: () => [] },
    dosId: { type: [Number, String], default: '' },
    chapterId: { type: [Number, String], default: '' },
    fetching: { type: Boolean, default: false },
  },
  provide() {
    return {
      reorderSource: 'data',
      reorderDisabled: 'loading',
      reorderWrapper: this,
    };
  },
  data() {
    return {
      loading: false,
      downloading: false,

      columns,
      components: {
        body: {
          wrapper: DraggableTableBody,
        },
      },
    };
  },
  methods: {
    handleTableRowOrderUpdate(evt) {
      this.saveOrder(evt);
    },
    async saveOrder(evt) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        code_id: this.dosId,
        chapter_id: this.chapterId,
        course_ids: this.data.map((i) => i.id).filter((i) => !!i).join(','),
      };
      const data = await reorderCourse(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '排序保存失败');
        this.rollbackReorder(evt);
        return;
      }
      this.$message.success(data.message || '排序保存成功');
    },
    rollbackReorder(evt) {
      const oldIndex = evt?.moved?.oldIndex;
      const newIndex = evt?.moved?.newIndex;
      const [movedItem] = this.data.splice(newIndex, 1);
      this.data.splice(oldIndex, 0, movedItem);
    },

    onEdit(record) {
      if (this.$refs.editCourseTitleModalRef?.show) {
        this.$refs.editCourseTitleModalRef.show({
          ...record,
        });
      }
    },
    editModalClosed({ item } = {}) {
      if (item.dos_course_id) {
        this.data.forEach((i) => {
          if (i.id === item.dos_course_id) {
            i.code_course_title = item.dos_title;
          }
        });
      }
    },

    onDelete(record) {
      if (this.loading) {
        return;
      }
      this.$confirm({
        content: `确认删除该课程？`,
        onOk: () => this.onConfirmDelete(record),
      });
    },
    async onConfirmDelete(record) {
      const params = {
        code_id: record.code_id || '',
        chapter_id: record.chapter_id,
        dos_course_id: record.id,
      };
      const data = await deleteCourse(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data.message || '删除成功');
      this.$emit('deleted', params);
    },

    viewLog(record) {
      this.$router.push({
        path: '/index/dos/list/log',
        query: {
          dosId: this.dosId,
          chapterId: record.chapter_id,
          dosCourseId: record.id,
          courseIds: record.course_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>

<template>
  <a-modal :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :width="800"
           :centered="true"
           :footer="null"
           wrap-class-name="create-code-modal"
           :after-close="afterClosed"
           @cancel="close()">
    <div class="flex container">
      <a-form-model ref="formRef"
                    :model="form"
                    :rules="rules"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}"
                    label-align="right"
                    class="form-model flex-grow mr-30"
                    @submit="onSubmit">
        <a-form-model-item label="二维码名称" prop="title" class="form-item">
          <a-input v-model="form.title"
                   :allow-clear="true"
                   :max-length="10"
                   placeholder="请填写二维码名称"/>
          <span>限10个字</span>
        </a-form-model-item>

        <a-form-model-item label="DOS码类型" prop="type" class="form-item">
          <a-radio-group v-model="form.type">
            <a-radio value="2">多目录（章节）</a-radio>
            <a-radio value="1">普通DOS码</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="图片类型" prop="picType" class="form-item">
          <a-radio-group v-model="form.picType" @change="drawLogo">
            <a-radio value="1">使用默认图片</a-radio>
            <a-radio value="2">使用自定义图片</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item v-show="form.picType === '2'"
                           label="上传图片" prop="pic_id" class="form-item">
          <a-upload :multiple="false"
                    accept=".jpg, .jpeg, .png"
                    list-type="picture-card"
                    action="/public/conf/upload"
                    :headers="{'X-Auth-Token': $store.state.User.token || ''}"
                    :data="{'source': 'transfer'}"
                    :disabled="form.picType === '1' || loading"
                    :show-upload-list="false"
                    @change="onPicUploaded">
            <div v-if="form.pic_id">
              <img :src="imgUrl" alt="" class="uploaded-pic">
            </div>
            <div v-else style="width: 180px;">
              <a-icon type="plus"/>
              <div>请上传正方形图片</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{span: 16, offset: 8}" class="mb-10">
          <a-button type="primary"
                    :loading="loading || uploading"
                    @click="onSubmit">
            提交并生成二维码
          </a-button>
        </a-form-model-item>
      </a-form-model>

      <div class="code-wrapper flex-none">
        <div class="example-text">示例</div>
        <!--<img :src="codeUrl" alt="">-->
        <canvas ref="canvasRef"
                width="200"
                height="250"/>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  getCodeInfo,
  saveCodeInfo,
} from '../api';
import {
  ossBaseUrl,
} from '@/Config';

export default {
  name: 'CreateCodeModal',
  computed: {
    rules() {
      return {
        title: [{ required: true, trigger: 'blur', message: '请填写二维码名称' }],
        type: [{ required: true, trigger: 'change', message: '请选择DOS码类型' }],
        picType: [{ required: true, trigger: 'change', message: '请选择图片类型' }],
        pic_id: [{
          required: this.form.picType === '2',
          trigger: 'blur',
          validator: this.picValidator,
        }],
      };
    },
  },
  watch: {
    'form.title'() {
      this.drawText();
    },
  },
  data() {
    return {
      shown: false,
      loading: false,
      uploading: false,

      id: '',
      codeUrl: '',
      imgUrl: '',
      defaultLogoUrl: '',

      ctx: null,

      form: {
        title: '',
        type: '1',
        picType: '1',
        pic_id: '',
      },
    };
  },
  methods: {
    picValidator(rule, value, callback) {
      if (this.form.picType === '2' && !value) {
        callback(new Error('请上传自定义图片'));
      } else {
        callback();
      }
    },
    show({ id } = {}) {
      this.id = id;
      this.shown = true;

      this.getCodeInfo();
      this.$nextTick(() => {
        if (!this.id) {
          this.draw();
        }
      });
    },
    close(evt) {
      this.$emit('closed', evt);
      this.shown = false;
    },
    afterClosed() {
      this.id = '';
      this.codeUrl = '';
      this.imgUrl = '';
      this.defaultLogoUrl = '';
      if (this.ctx) {
        this.ctx.clearRect(0, 0, 200, 250);
        this.ctx = null;
      }
      this.form = {
        title: '',
        type: '1',
        picType: '1',
        pic_id: '',
      };
    },

    async getCodeInfo() {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const data = await getCodeInfo({
        code_id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取信息失败');
        return;
      }
      const result = data?.data || {};
      this.form = {
        title: result.title || '',
        type: result.type + '',
        picType: +result.is_default_pic === 2 ? '2' : '1',
        pic_id: result.logo_resource_id || '',
      };

      this.codeUrl = result.code_url || '';
      this.imgUrl = result.logo_url || '';
      this.defaultLogoUrl = result.default_logo_url || '';

      this.draw();
    },

    onPicUploaded(evt) {
      this.uploading = evt.file.status === 'uploading';

      if (evt.file.status === 'done') {
        const res = evt.file.response || undefined;
        if (!res || res.error_code) {
          this.$message.error(res?.message || '图片上传失败');
        } else {
          this.$message.success(res.message || '图片上传成功');
          this.form.pic_id = res.data?.file_resource_id || '';
          this.imgUrl = res.data?.url || '';

          this.draw();
        }
      }
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((validated) => {
          if (validated) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
    onSubmit() {
      this.validate().then(() => {
        this.onSave();
      });
    },
    async onSave() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
      };
      delete params.picType;
      if (this.id) {
        params.id = this.id;
      }

      const data = await saveCodeInfo(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.close({ all: !this.id, item: params });
    },

    draw() {
      if (!this.$refs.canvasRef) {
        return;
      }
      const canvas = this.$refs.canvasRef;
      this.ctx = canvas.getContext('2d');

      this.ctx.width = 200;
      this.ctx.heigth = 250;

      this.drawCode();
      this.drawText();
    },
    drawCode() {
      if (!this.ctx) {
        return;
      }
      const codeImg = new Image();
      codeImg.onload = () => {
        this.ctx.drawImage(codeImg, 0, 0, 200, 200);
        this.drawLogo();
      };
      codeImg.src = `${ossBaseUrl}placeholder/qr_code.png`;
    },
    drawLogo() {
      if (!this.ctx) {
        return;
      }
      const logoImg = new Image();
      logoImg.onload = () => {
        this.ctx.clearRect(68, 64, 64, 64);
        this.ctx.drawImage(logoImg, 71, 67, 58, 58);
      };
      if (this.form.picType === '1') {
        logoImg.src = this.defaultLogoUrl;
      } else {
        logoImg.src = this.imgUrl;
      }
    },
    drawText() {
      if (!this.ctx) {
        return;
      }
      this.ctx.fillStyle = '#FFFFFF';
      this.ctx.fillRect(0, 205, 200, 140);
      this.ctx.fillStyle = '#000000';
      this.ctx.font = '16px noto_sans';
      this.ctx.textAlign = 'center';
      this.ctx.textBaseline = 'top';
      this.ctx.fillText(this.form.title, 100, 210);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 80vw;
  max-height: 80vh;
}

.form-model {
  width: 450px;
  flex: none !important;
}

.example-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333333;
}

.code-wrapper {
  width: 200px;
  min-height: 200px;
  //background-color: rebeccapurple;
}

.uploaded-pic {
  width: 100px;
  height: 100px;
}
</style>

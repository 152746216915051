<template>
  <div>
    <div class="right-top-btn-group">
      <a-button @click="back">返回</a-button>
      <a-button type="primary" @click="createCourse">添加课程</a-button>
    </div>

    <CourseList :data="list || []"
                :dos-id="id"
                :fetching="loading"
                @deleted="onDeleteCourse"/>

    <SelectCourseModal ref="selectCourseModalRef"
                       @closed="onSelectCourseClosed"/>
  </div>
</template>

<script>
import {
  getDosDetailList,
} from '../api';

import CourseList from '../components/CourseList.vue';
import SelectCourseModal from '../components/SelectCourseModal.vue';

export default {
  name: 'Course',
  components: {
    CourseList,
    SelectCourseModal,
  },
  data() {
    return {
      loading: false,

      id: '',
      list: [],
    };
  },
  created() {
    this.id = this.$route.query?.dosId || '';
    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    onSearch() {
      this.getData();
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getDosDetailList({
        code_id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.list = data?.data[0]?.course || [];
    },

    createCourse() {
      this.onAddCourse();
    },
    editChapter(evt) {
      this.onAddCourse(evt);
    },
    onAddCourse() {
      if (this.$refs.selectCourseModalRef?.show) {
        this.$refs.selectCourseModalRef.show({
          code_id: this.id,
        });
      }
    },
    onSelectCourseClosed(evt) {
      if (evt?.modified) {
        this.onSearch();
      }
    },

    onDeleteCourse() {
      this.onSearch();
    },

  },
};
</script>

<style scoped lang="scss">
.right-top-btn-group {
  position: relative;
  top: -40px;
  float: right;
  height: 0;
}
</style>

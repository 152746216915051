import { render, staticRenderFns } from "./DraggableTableBody.vue?vue&type=template&id=39c4a032&scoped=true"
import script from "./DraggableTableBody.vue?vue&type=script&lang=js"
export * from "./DraggableTableBody.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c4a032",
  null
  
)

export default component.exports
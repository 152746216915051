<template>
  <div>
    <a-form-model layout="inline">
      <a-form-model-item label="学员搜索">
        <a-input v-model="form.uid_name_mobile_idcard"
                 :allow-clear="true"
                 style="width: 240px;"
                 placeholder="姓名/手机号/身份证号"/>
      </a-form-model-item>
      <a-form-model-item label="岗位">
        <app-select-job v-model="form.post_ids"/>
      </a-form-model-item>
      <a-form-model-item label="课程">
        <SelectCourse v-model="form.course_ids"/>
      </a-form-model-item>
      <a-form-model-item label="时间">
        <a-range-picker v-model="form.date"/>
      </a-form-model-item>
      <a-form-model-item class="full-width flex flex-end" style="text-align: right;">
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button class="mr-30" @click="onReset">重置</a-button>
        <a-button v-if="$route.query.dosId" @click="back">返回</a-button>
      </a-form-model-item>
    </a-form-model>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="mt-20 table small-cell-table">
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  getDosLog,
} from './api';

import Pagination, { getPagination, updatePagination } from '@components/Pagination.vue';
import SelectCourse from './components/SelectCourse.vue';

const form = {
  uid_name_mobile_idcard: '',
  post_ids: '',
  course_ids: '',
  start_day: '',
  end_day: '',
  date: [undefined, undefined],
};

const columns = [
  {
    width: '120px',
    align: 'center',
    title: '课程名称',
    key: 'title',
    dataIndex: 'title',
  },
  {
    width: '50px',
    align: 'center',
    title: '访问来源',
    key: 'access_source_name',
    dataIndex: 'access_source_name',
  },
  {
    width: '40px',
    align: 'center',
    title: 'UID',
    key: 'userid',
    dataIndex: 'userid',
  },
  {
    width: '50px',
    align: 'center',
    title: '姓名',
    key: 'truename',
    dataIndex: 'truename',
  },
  {
    width: '60px',
    align: 'center',
    title: '手机号',
    key: 'mobile',
    dataIndex: 'mobile',
  },
  {
    width: '100px',
    align: 'center',
    title: '身份证号',
    key: 'idcard',
    dataIndex: 'idcard',
  },
  {
    width: '80px',
    align: 'center',
    title: '岗位',
    key: 'post_name',
    dataIndex: 'post_name',
  },
  {
    width: '50px',
    align: 'center',
    title: '进入时间',
    key: 'created',
    dataIndex: 'created',
  },
  {
    width: '40px',
    align: 'center',
    title: '浏览时间',
    key: 'longtime',
    dataIndex: 'longtime',
  },
];

export default {
  name: 'Log',
  components: {
    Pagination,
    SelectCourse,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      form: { ...form },

      columns,
      data: [],
      pagination: { ...getPagination() },
    };
  },
  created() {
    this.form.course_ids = this.$route.query.courseIds || '';
    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    onReset() {
      this.form = { ...form };
      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
        start_day: this.form.date[0]?.format('YYYY-MM-DD') || '',
        end_day: this.form.date[1]?.format('YYYY-MM-DD') || '',
        page,
        per_page: pageSize,
      };
      delete params.date;

      const data = await getDosLog(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },

    onDownload(record) {
      console.log(record);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
